<template>
  <div>
    <AppCollapse
      type="border-info-2 card"
      class="mb-50"
    >
      <AppCollapseItem
        is-visible
        title
        class-header="card-header bg-blue-gradient p-0 m-50"
        class="border-info-2"
      >
        <template #header>
          <div class="d-flex-between w-100 pr-50">
            <div class="d-flex-center justify-content-start gap-1">
              <b-img
                src="@icons/passengers.svg"
                class="icon-card"
                width="50px"
              />
              <h6 class="text-airline fw-700 font-medium-3 m-0">
                {{ $t('reservation.passengerDetails') }}
              </h6>
            </div>
            <div style="margin-right: 2.5rem;">
              <BButton
                v-if="reservationsData?.paxLists.length > 0"
                variant="flat-primary"
                class="p-50 rounded-circle m-0"
                @click.prevent.stop="handleCopyPassengers"
              >
                <feather-icon
                  v-b-tooltip.hover.window
                  icon="CopyIcon"
                  size="18"
                  class="text-airline"
                  :title="`${$t('copy')} ${$t('reservation.passenger')}`"
                />
              </BButton>
            </div>
          </div>
        </template>

        <b-row>
          <b-col
            v-for="(pax, paxIndex) in reservationsData.paxLists"
            :key="paxIndex"
            cols="12"
            md="6"
            class="my-50"
          >
            <PaxDetail
              :pax="pax"
              :pax-index="paxIndex"
              :reservations-data="reservationsData"
              :is-hide-function-button="isHideFunctionButton"
              :hide-feature="hideFeature"
              :is-booking-out-system="isBookingOutSystem"
              @openEditTravelFlyPassengerModalHandle="openEditTravelFlyPassengerModalHandle(pax)"
              @handleDeleteInfant="handleDeleteInfant(reservationsData, pax)"
              @deleteBSVHandle="deleteBSVHandle(reservationsData, pax)"
            />
          </b-col>
        </b-row>

        <b-card
          v-if="!isEmpty(reservationsData?.paxListsCancelled)"
          header-bg-variant="light-danger"
          header-class="p-1 py-50"
          body-class="p-1 py-50"
          class="border mt-1"
        >
          <template #header>
            <div class="font-medium-1 fw-800 text-danger">
              Hành khách đã huỷ
            </div>
          </template>
          <div
            v-for="(p, pIndex) in reservationsData.paxListsCancelled"
            :key="pIndex"
            cols="12"
            md="6"
            class="my-50"
          >
            <div class="d-flex flex-column align-items-start mr-50">
              <div class="font-medium-3 text-danger">
                <span>{{ `${pIndex + 1}. ` }}</span>
                <code class="text-danger font-italic bg-transparent pl-0 pr-25">
                  {{ `${p.paxType ? `(${$t(`reservation.${p.paxType}`)})` : ''}` }}
                </code>
                <b>{{ `${p?.lastName} / ${p?.firstName} ${p?.title || ''} ` }}</b>
              </div>
            </div>
          </div>
        </b-card>
      </AppCollapseItem>
    </AppCollapse>

    <div
      v-if="!isHideFunctionButton && !hideFeature"
      class="d-flex-center justify-content-end gap-1 my-50 flex-wrap"
    >
      <!-- ANCHOR Thêm hành lý -->
      <b-button
        v-if="fnAncillary?.BAGGAGE?.available || enableDevelopEnv('baggage', bookingSource)"
        class="d-flex-center text-nowrap px-75"
        variant="primary"
        :disabled="!canAccess('booking.addBag') || isBookingOutSystem || !canIssueLimit || reservationsData.status === 'CANCEL'"
        size="sm"
        @click="showEditBaggage"
      >
        <b-img
          src="@icons/extra-luggage.svg"
          width="17"
          class="mr-25"
        />
        <span
          class="font-small-4"
          style="padding-bottom: 1px;"
        >
          {{ $t('reservation.addBaggage') }}
        </span>
      </b-button>

      <!-- ANCHOR Thêm chỗ ngồi-->
      <b-button
        v-if="fnAncillary?.SEAT?.available || enableDevelopEnv('seat', bookingSource)"
        class="d-flex-center text-nowrap px-75"
        variant="primary"
        :disabled="!canAccess('booking.addSeat') || isBookingOutSystem || !canIssueLimit || reservationsData.status === 'CANCEL'"
        size="sm"
        @click="showEditSeat"
      >
        <b-img
          src="@icons/chair-blue.svg"
          width="17"
          class="mr-25"
        />
        <span
          class="font-small-4"
          style="padding-bottom: 1px;"
        >
          {{ $t('reservation.addSeat') }}
        </span>
      </b-button>

      <!-- ANCHOR Thêm dịch vụ khác -->
      <b-button
        v-if="fnAncillary?.OTHER?.available || enableDevelopEnv('other', bookingSource)"
        class="d-flex-center text-nowrap px-75"
        variant="primary"
        :disabled="!canAccess('booking.addSeat') || isBookingOutSystem || !canIssueLimit || reservationsData.status === 'CANCEL'"
        size="sm"
        @click="showEditOther"
      >
        <b-img
          src="@icons/dots-vertical.svg"
          width="17"
          class="mr-25"
        />
        <span
          class="font-small-4"
          style="padding-bottom: 1px;"
        >
          {{ $t('reservation.addServices') }}
        </span>
      </b-button>

      <!-- ANCHOR Sửa thông tin -->
      <b-button
        v-if="['VJ'].includes(reservationsData.source)"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :disabled="isBookingOutSystem || !canIssueLimit || ['CANCEL'].includes(reservationsData.status)"
        class="px-75 d-flex-center gap-1"
        size="sm"
        variant="primary"
        @click="openModifyChangePassengersInfo"
      >
        <IAmIcon
          icon="pencil"
          size="15"
        />
        <span class="font-small-4">{{ $t('reservation.modifyPaxsInfo') }}</span>
      </b-button>

      <!-- ANCHOR Thêm em bé -->
      <b-button
        v-if="SOURCES_CAN.ADD_INFANT.includes(reservationsData.source)"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="primary"
        :style="['VU'].includes(reservationsData.source) ? `max-width: 340px;` : ''"
        class="px-75 d-flex-center gap-1"
        size="sm"
        :disabled="isBookingOutSystem || !canIssueLimit || reservationsData.status === 'CANCEL'"
        @click="openAddInfantModalHandle(reservationsData.paxLists, reservationsData)"
      >
        <b-img
          src="@icons/increase.svg"
          width="16"
        />
        <span class="font-small-4">{{ $t('reservation.addInfant') }}</span>
      </b-button>

      <!-- ANCHOR Sửa hộ chiếu -->
      <b-button
        v-if="['1G', 'VJ'].includes(reservationsData.source) || (['1A'].includes(reservationsData.source) && !NDC_1A_BOOKING)"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :disabled="isBookingOutSystem || reservationsData.status === 'CANCEL'"
        class="px-75 d-flex-center gap-1"
        size="sm"
        variant="primary"
        @click="openModifyDocumentsModalHandle(reservationsData)"
      >
        <IAmIcon
          icon="pencil"
          size="15"
        />
        <span class="font-small-4">{{ ['VJ'].includes(reservationsData.source) ? $t('reservation.modifyDocumentsVJ') : $t('reservation.modifyDocuments') }}</span>
      </b-button>

      <!-- ANCHOR Thêm thẻ bsv -->
      <b-button
        v-if="['VN1A', 'VN1A_MT'].includes(reservationsData.source)"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="primary"
        class="px-75 d-flex-center gap-1"
        size="sm"
        :disabled="isBookingOutSystem"
        @click="openAddMultiFftvModal"
      >
        <feather-icon icon="CreditCardIcon" />
        <span class="font-small-4">{{ $t('reservation.addBSV') }}</span>
      </b-button>

      <!-- ANCHOR Sửa ctcm ctce 1g -->
      <b-button
        v-if="['1G'].includes(reservationsData.source)"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="primary"
        class="px-75 d-flex-center gap-1"
        size="sm"
        :disabled="isBookingOutSystem"
        @click="openEditCtcmCtceFor1gModal"
      >
        <feather-icon icon="InboxIcon" />
        <span class="font-small-4">{{ $t('reservation.editCtcmCtceFor1g.title') }}</span>
      </b-button>

      <!-- ANCHOR Thêm giấy tờ VN1A -->
      <b-button
        v-if="['VN1A', 'VN1A_MT'].includes(reservationsData.source)"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :disabled="isBookingOutSystem"
        class="px-75 d-flex-center gap-1"
        size="sm"
        variant="primary"
        @click="openAddDocumentsModal"
      >
        <IAmIcon
          icon="addReceipt"
          size="16"
        />
        <span class="font-small-4">{{ $t('reservation.addDocuments.title') }}</span>
      </b-button>

      <!-- ANCHOR Tách hành khách -->
      <b-button
        v-if="canSplitPnr(reservationsData)"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="primary"
        class="px-75 d-flex-center gap-1"
        size="sm"
        :disabled="!canAccess('booking.diveBooking') || isBookingOutSystem || !canIssueLimit || reservationsData.status === 'CANCEL'"
        @click="openSeparateBookingModalHandle"
      >
        <b-img
          src="@icons/split-pax.svg"
          width="16"
        />
        <span class="font-small-4">{{ $t('reservation.separateBooking') }}</span>
      </b-button>
    </div>

    <ModalSeparateBooking />
    <ModalAddInfant
      :adult-option="adultOptions"
      :booking-data="reservationsData"
    />
    <ModalModifyDocuments
      v-if="paxListsData"
      :pax-lists="paxListsData"
      :reservations-data="reservationsData"
    />
    <ModalModifyDocumentsVJ
      v-if="['VJ'].includes(reservationsData.source) && paxListsData"
      :pax-lists="paxListsData"
      :reservations-data="reservationsData"
    />
    <ModalEditTravelFlyPassenger
      v-if="!isEmpty(passengerData)"
      :reservations-data="reservationsData"
      :passenger-data="passengerData"
    />
    <ModalAddMultiFftv
      v-if="['VN1A', 'VN1A_MT'].includes(reservationsData.source)"
      :booking-code="reservationsData.bookingCode"
      :source="reservationsData.source"
      :pax-lists="reservationsData.paxLists"
      :default-phone-number="reservationsData.contact.paxInfo.phone[0]?.address"
      :default-email-address="reservationsData.contact.paxInfo.email[0]?.address"
    />
    <ModalEditCtcmCtceFor1g
      v-if="['1G'].includes(reservationsData.source)"
      :booking-code="reservationsData.bookingCode"
      :pax-lists="reservationsData.paxLists"
      :source="reservationsData.source"
      :agency-code="reservationsData?.createdBy?.agency?.agencyCode"
      :pax-code="reservationsData.paxContact.code"
      :ssr-lists="reservationsData.ssr"
    />
    <ModalAddDocumentsVN1A
      v-if="['VN1A', 'VN1A_MT'].includes(reservationsData.source)"
      :booking-data="reservationsData"
    />
    <ModalEditInfoPassenger />
  </div>
</template>

<script>
import {
  BCol, BRow, BButton, VBTooltip, BImg, BCard,
} from 'bootstrap-vue'
import { ref, computed, toRefs } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import isEmpty from 'lodash/isEmpty'
import { useClipboard } from '@vueuse/core'

import { convertISODateTime } from '@/@core/utils/filter'
import { SOURCES_CAN } from '@/constants/reservation'

import useReservationHandle from '@reservation/useReservationHandle'
import useAncillaryServicesHandle from '@reservation/reservation-modify/components/detail/ancillary-services/useAncillaryServicesHandle'

import useToast from '@useToast'

import ModalEditTravelFlyPassenger from './ModalEditTravelFlyPassenger.vue'

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BImg,
    BCard,

    ModalEditTravelFlyPassenger,

    ModalSeparateBooking: () => import('./ModalSeparateBooking.vue'),
    ModalAddInfant: () => import('./ModalAddInfant.vue'),
    ModalModifyDocuments: () => import('./ModalModifyDocuments.vue'),
    ModalModifyDocumentsVJ: () => import('./ModalModifyDocumentsVJ.vue'),
    ModalAddMultiFftv: () => import('./ModalAddMultiFftv.vue'),
    ModalEditCtcmCtceFor1g: () => import('./ModalEditCtcmCtceFor1g.vue'),
    AppCollapse: () => import('@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@core/components/app-collapse/AppCollapseItem.vue'),
    PaxDetail: () => import('./PaxDetail.vue'),
    ModalAddDocumentsVN1A: () => import('./ModalAddDocumentsVN1A.vue'),
    ModalEditInfoPassenger: () => import('./ModalEditInfoPassenger.vue'),
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    reservationsData: {
      type: Object,
      required: true,
    },
    isBookingOutSystem: {
      type: Boolean,
      default: false,
    },
    isHideFunctionButton: {
      type: Boolean,
      default: false,
    },
    canIssueLimit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root }) {
    const {
      filterAdultPassengers,
      modifyFrequentFlyer,
      modifyPaxInfo,
      hideFeature,
      delay,
      NDC_1A_BOOKING,
    } = useReservationHandle()
    const { toastError, toastSuccess } = useToast()

    const passengerData = ref({})
    const adultOptions = ref([])

    function deleteBSVHandle(bookingData, pax) {
      const ope = {
        operation: 'DELETE',
        id: pax.travelFlyer?.id || '',
        frequentFlyer: {
          airline: bookingData.airlines.split(' | ')[0],
          supplierCode: bookingData.airlines.split(' | ')[0],
          number: '',
        },
      }
      // show modal confirm to modify
      const titleMsg = () => this.$createElement('div', {
        domProps: { innerHTML: 'Xác nhận xoá thẻ thành viên?' },
      })

      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Xoá thẻ',
          cancelTitle: this.$t('reservation.back'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')
            modifyFrequentFlyer(bookingData, pax, ope, ope.frequentFlyer)
              .then(() => {
                toastSuccess({
                  title: 'Thành công',
                  content: 'Xoá thẻ thành viên thành công',
                })
              })
              .catch(error => {
                console.error({ error })
                toastError({
                  title: 'Lỗi',
                  content: 'Lỗi xoá thẻ thành viên, vui lòng kiểm tra lại',
                })
              })
              .finally(() => {
                this.$bvModal.hide('modal-api-loading')
              })
          }
        })
    }

    function openSeparateBookingModalHandle() {
      this.$bvModal.show('modal-separate-booking')
    }

    async function openAddInfantModalHandle(paxLists, bookingData) {
      // Xoá/ thêm em bé chỉ có vé HOLD, và parent adult nào chưa có Inf thì mới được thêm (https://discord.com/channels/1054696448110903327/1293140190524866637/1293154935193735199)
      if (bookingData.source === 'VN1A' && bookingData.status !== 'HOLD') {
        toastError('Vietnam Airlines chỉ xoá/thêm em bé với mã đặt chỗ đang GIỮ CHỖ.')
        return
      }
      adultOptions.value = []
      await new Promise(resolve => {
        adultOptions.value = filterAdultPassengers(paxLists)
        resolve()
      })

      this.$bvModal.show('modal-modify-add-infant')
    }

    const paxListsData = ref([])

    async function openModifyDocumentsModalHandle(data) {
      paxListsData.value = []
      paxListsData.value = data.paxLists.filter(p => !['INFANT'].includes(p.paxType) && (['1A'].includes(data.source) ? isEmpty(p.document) : true))

      await delay(200)

      if (isEmpty(paxListsData.value)) {
        toastError({
          title: 'messagesList.error',
          content: 'reservation[\'No passengers can add passports\']',
        })
        return
      }

      if (['VJ'].includes(data.source)) {
        this.$bvModal.show('modal-modify-documents-VJ')
      } else {
        this.$bvModal.show('modal-modify-documents')
      }
    }

    function openModifyChangePassengersInfo() {
      this.$bvModal.show('modal-modify-change-passengers-info')
    }

    async function openEditTravelFlyPassengerModalHandle(data) {
      if (!data.travelFlyer) {
        passengerData.value = {}
        await new Promise(resolve => {
          passengerData.value = data
          resolve()
        })

        this.$bvModal.show('modal-modify-add-travel-fly-passenger')
      } else {
        toastError({
          title: 'messagesList.error',
          content: 'reservation[\'Passengers who already have a membership card, please delete the old card if you want to change!\']',
        })
      }
    }

    function handleDeleteInfant(bookingData, inf) {
      const parent = bookingData.paxLists.find(p => p.paxId === inf.parentPaxId)

      if (!parent) {
        toastError({
          title: 'Lỗi',
          content: 'reservation[\'Error in retrieving information of adults accompanying the infant!\']',
        })
        return
      }

      const payload = {
        paxUpdates: [
          {
            paxInfo: {
              firstName: inf.firstName,
              lastName: inf.lastName,
              title: inf?.title || '',
              paxType: inf.paxType,
              birthday: inf?.birthday || null,
              paxId: inf.paxId,
              parentPaxId: inf.parentPaxId,
              familyId: inf?.familyId || '',
            },
            operation: 'DELETE',
            id: '1',
          },
          {
            paxInfo: {
              firstName: parent.firstName,
              lastName: parent.lastName,
              title: parent?.title || '',
              paxType: parent.paxType,
              birthday: parent?.birthday || null,
              paxId: parent.paxId,
              parentPaxId: parent?.parentPaxId || '',
              familyId: parent?.familyId || '',
            },
            operation: 'UPDATE',
            id: '1',
          },
        ],
      }

      // Show modal confirm to modify
      const titleMsg = () => this.$createElement('div', {
        domProps: { innerHTML: root.$t('reservation.removeInfantConfirm', { lastName: inf.lastName, firstName: inf.firstName }) },
      })

      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('reservation.removeInfant'),
          cancelTitle: this.$t('reservation.back'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')

            modifyPaxInfo(bookingData, payload, false)
              .then(() => {
                toastSuccess({
                  title: 'Thành công',
                  content: 'Xoá em bé thành công!',
                })
              })
              .catch(error => {
                console.error({ error })
                toastError({
                  title: 'Lỗi',
                  content: 'Lỗi xoá em bé, vui lòng kiểm tra lại!',
                })
              })
              .finally(() => {
                this.$bvModal.hide('modal-api-loading')
              })
          }
        })
    }

    function openAddMultiFftvModal() {
      this.$bvModal.show('modal-add-multi-fftv')
    }

    function openEditCtcmCtceFor1gModal() {
      this.$bvModal.show('modal-edit-ctcm-ctce-for-1g-modal')
    }
    const { copy } = useClipboard()
    function handleCopyPassengers() {
      const textValue = props?.reservationsData?.paxLists?.map(pax => {
        // eslint-disable-next-line prefer-template
        const title = pax?.title ? pax.title + ' ' : ''
        // eslint-disable-next-line prefer-template
        const birthday = pax.birthday ? ' ' + convertISODateTime(pax.birthday).date : ''

        const document = pax.document?.find(doc => doc.docType && ['P', 'C'].includes(doc.docType))
        let documentString = ''
        if (document) {
          // eslint-disable-next-line prefer-template
          documentString = document.number
          // if (document.docType === 'P') {
          //   // eslint-disable-next-line prefer-template
          //   documentString += ' ' + document.nationality + ' ' + (convertISODateTime(document?.endDate)?.date || document?.endDate) + ' ' + document.isCountry
          // }
        }

        // eslint-disable-next-line prefer-template
        return title + pax.lastName + ' ' + pax.firstName + ' ' + birthday + ' ' + documentString
      }).join('\n')
      copy(textValue)
        .then(() => {
          this.$root.toastSuccess('reservation.sms.copySuccess')
        })
        .catch(() => {
          this.$root.toastError('reservation.sms.copyFail')
        })
    }

    const { fnAncillary, bookingSource, enableDevelopEnv } = useAncillaryServicesHandle()

    const reservationsData = toRefs(props).reservationsData
    const isDisableAddAncillaryQH = computed(() => ['QH', 'TH'].includes(reservationsData.value?.source) && !['PAID', 'PARTIALLY_PAID'].includes(reservationsData.value?.status))

    // ANCHOR Edit Baggage
    function showEditBaggage() {
      if (isDisableAddAncillaryQH.value) {
        toastError({
          title: `${root.$te(`flight.airlines.${bookingSource.value}`) ? root.$t(`flight.airlines.${bookingSource.value}`) : bookingSource.value}: Vui lòng thanh toán trước khi thêm dịch vụ hãng!`,
        })
        return
      }
      if (reservationsData.value && isEmpty(reservationsData.value.paxLists.filter(p => p.paxType !== 'INFANT'))) {
        toastError({
          title: 'Mua thêm hành lý  chỉ áp dụng đối với mã đặt chỗ có hành khách người lớn hoặc trẻ em!',
        })
        return
      }
      if (reservationsData.value && isEmpty(reservationsData.value.itineraries)) {
        toastError({
          title: 'Không có hành trình trong mã đặt chỗ hiện tại!',
        })
        return
      }
      this.$bvModal.show('modal-modify-ancillary-baggage-select-pax')
    }

    // ANCHOR Edit Seat
    function showEditSeat() {
      if (isDisableAddAncillaryQH.value) {
        toastError({
          title: 'Bamboo Airways: Vui lòng thanh toán trước khi thêm dịch vụ hãng!',
        })
        return
      }
      this.$bvModal.show('modal-modify-ancillary-seat-details')
    }

    // ANCHOR Edit Other
    async function showEditOther() {
      if (isDisableAddAncillaryQH.value) {
        toastError({
          title: `${root.$te(`flight.airlines.${bookingSource.value}`) ? root.$t(`flight.airlines.${bookingSource.value}`) : bookingSource.value}: Vui lòng thanh toán trước khi thêm dịch vụ hãng!`,
        })
        return
      }
      this.$bvModal.show('modal-modify-ancillary-other-select-pax')
    }

    function openAddDocumentsModal() {
      this.$bvModal.show('modal-add-documents-vn1a')
    }

    function canSplitPnr(data) {
      if (['1G'].includes(data.source) && ['GDS'].includes(data?.extra?.[0]?.contentSource)) return true
      if (!['AK', 'F1', 'TH', '1A', '1G'].includes(data.source)) return true
      return false
    }

    const isProduction = computed(() => process.env.VUE_APP_ENV === 'production')

    return {
      passengerData,
      paxListsData,
      adultOptions,
      NDC_1A_BOOKING,
      isProduction,
      deleteBSVHandle,
      handleDeleteInfant,
      convertISODateTime,

      openSeparateBookingModalHandle,
      openAddInfantModalHandle,
      openModifyDocumentsModalHandle,
      openModifyChangePassengersInfo,
      openEditTravelFlyPassengerModalHandle,

      isEmpty,
      openAddMultiFftvModal,
      openEditCtcmCtceFor1gModal,
      handleCopyPassengers,
      hideFeature,

      fnAncillary,
      showEditBaggage,
      showEditSeat,
      showEditOther,
      openAddDocumentsModal,

      canSplitPnr,
      SOURCES_CAN,

      bookingSource,
      enableDevelopEnv,
    }
  },
}
</script>

  <style lang="">

  </style>
